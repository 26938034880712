<template>
<v-autocomplete
  cache-items
  v-model="product"
  :items="products"
  return-object
  :dense="dense"
    hide-details
  outlined
  label="Serviço"
  :loading="loading"
  :search-input.sync="searchKeyWord"
  item-text="description" item-value="id"
  no-filter

  >

    <!-- <template v-slot:no-data>
        <v-list-item>
            <v-list-item-title>
                <strong>{{ message }}</strong>
            </v-list-item-title>
        </v-list-item>
    </template> -->
</v-autocomplete>
</template>

<script>
import axios from '@/plugins/axios'
export default {
    props: {
        value: {},
        dense: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        products: [],
        loading: false,
        product:null,
        searchKeyWord: '',
        message: '',
    }),
    watch: {
        value(val) {
            this.products = []
            this.products.push(val)
            this.product = val
        },
        product(){
           this.$emit('input',this.product)
        },
         searchKeyWord(){
         this.loading = true
            this.message = 'Carregando aguarde.'
            axios
                .post('helper/get_services',{ search: this.searchKeyWord })
                .then(response => {
                    this.products = response.data
                    // if (this.products.length == 0) {
                    //     this.message = 'Nenhum registro encontrado.'
                    // }
                })
                .finally(() => {
                    this.loading = false
                })
      }
    },
    created() {
        if(this.value){
          this.products = []
          this.products.push(this.value)
          this.product = this.value
        }


    },
    computed: {
      // searchWord() {
      //       if (this.searchKeyWord == null) {
      //           return ''
      //       }
      //       return this.searchKeyWord
      //   }

    },

    methods: {
        change(){
            this.$emit('input', this.guest)
            this.$emit('change', this.guest)
        },
        search() {
            this.loading = true
            this.message = 'Carregando aguarde.'
            axios
                .post('helper/get_services',{ search: this.searchWord })
                .then(response => {
                    this.products = response.data
                    // if (this.products.length == 0) {
                    //     this.message = 'Nenhum registro encontrado.'
                    // }
                })
                .finally(() => {
                    this.loading = false
                })
        },

    },
}
</script>

<style>
</style>
