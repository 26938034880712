<template>
<v-autocomplete v-model="guest" return-object :dense="dense" filled outlined label="Reserva" :items="guests" :loading="loading" :search-input.sync="searchKeyWord" item-value="id" @click="sendRequest" @keyup="search" @change="emit">
    <!-- <template v-slot:item="{ item }">
        {{ rowautocomplete(item)  }}
    </template> -->

    <template v-slot:no-data>
        <v-list-item>
            <v-list-item-title>
                <strong>{{ message }} </strong>
            </v-list-item-title>
        </v-list-item>
    </template>
</v-autocomplete>
</template>

<script>
import axios from '@/plugins/axios'
export default {
    props: {
        value: {},
        product: {
            type: Object
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        guests: [],
        loading: false,
        guest: null,
        searchKeyWord: '',
        message: '',
    }),
    watch: {
        value(val) {
            this.guests = []
            this.guests.push(val)
            this.guest = val
            this.searchKeyWord = val
        },

    },
    created() {
        this.searchKeyWord = ''
        if (this.value) {
            this.guests = []
            this.guests.push(this.value)
            this.guest = this.value
        }

    },
    computed: {
        searchWord() {
            if (this.searchKeyWord == null) {
                return ''
            }
            return this.searchKeyWord

        }
    },
    methods: {
        rowautocomplete(item) {
            let name
            if (item.company) {
                name = item.company.fantasy_name
            } else if(item.guest.length>0) {
                name = item.guest[0].name
            }else if(item.guest_unknown.length > 0){
                name = item.guest_unknown[0].name
            }

            return name+' | '+item.id+' | '+this.formatDate(item.check_in)+' - '+this.formatDate(item.check_out)
        },
        emit() {
            this.$emit('input', this.guest)
            this.$emit('change', this.guest)
        },

        search() {
            // if (this.searchWord.length < 3) {
            //     return false
            // }
            this.sendRequest()
        },
        click() {

        },
        sendRequest() {
            this.loading = true
            this.message = 'Carregando aguarde.'
            this.guests = []
            axios
                .post('helper/get_actived_reserves', {
                    search: this.searchWord
                })
                .then(response => {
                    this.guests = response.data

                    if (this.guests.length == 0) {
                        this.message = 'Nenhum registro encontrado.'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        formatDate(date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        },
        

    },
}
</script>

<style>
</style>
