<template>
    <div>

        <v-row no-gutters>
            <v-col>
                <v-card outlined>
                    <v-data-table height="210" :headers="headers" dense :items="guests" sort-by="calories"
                        class="elevation-0" :footer-props="{
                            itemsPerPageText: 'Itens por pagina',
                            itemsPerPageOptions: [5],
                            showFirstLastPage: true,
                        }">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Hóspedes</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical>
                                </v-divider>
                                <AutocompleteSearchGuest :errors="errors" v-model="guest" :dense="true" class="mt-6" />
                                <v-btn class="ml-2" color="primary" :disabled="!guest" @click="sendAddGuest()">
                                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                                    Adicionar
                                </v-btn>
                                <v-btn color="secondary" dark class="mb-1 ml-4" @click="showRegiterSimple()">
                                    <v-icon small>
                                        {{ icons.mdiPlus }}
                                    </v-icon>
                                    Hóspede
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon :color="guests.length === 1 ? 'grey' : 'error'"
                                :class="{ 'disabled-icon': guests.length === 1 }"
                                @click="guests.length !== 1 ? removeGuest(item) : null"
                                :style="{ cursor: guests.length === 1 ? 'not-allowed' : 'pointer' }">
                                {{ icons.mdiDeleteOutline }}
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            <div> Adicione o primeiro hóspede</div>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <RegisterSimpleGuest v-model="dialogRegisterSimpleGuest" @result="resultRegisterGuest"></RegisterSimpleGuest>
    </div>
</template>

<script>
import AutocompleteSearchGuest from '@/components/AutocompleteSearchGuest.vue'
import RegisterSimpleGuest from './RegisterSimpleGuest.vue'
import {
    mdiDeleteOutline,
    mdiPlus
} from '@mdi/js'
import {
    mapGetters,
    mapMutations
} from 'vuex'
import RegisterSimpleGuestVue from '@/views/Reserve/_components/RegisterSimpleGuest.vue'
import guest from '@/store/register/guest/guest'

export default {
    props: {
        value: {},
        errors: {},
        guests: {
            type: Array,
            required: true
        },
    },
    components: {
        AutocompleteSearchGuest,
        RegisterSimpleGuest
    },

    data: () => ({
        dialogRegisterSimpleGuest: false,
        guest: '',
        headers: [{
            text: 'NOME',
            align: 'start',
            value: 'name',
        },
        {
            text: 'TELEFONE',
            value: 'cell_phone',
        },
        {
            text: 'EMAIL',
            value: 'email',
        },
        {
            text: '',
            align: 'end',
            value: 'actions',
        }
        ],
        icons: {
            mdiDeleteOutline,
            mdiPlus
        }

    }),
    watch: {
        value(val) {
            this.guests = val
        },
        guests(val) {
            this.$emit('input', val)
        }
    },
    computed: {},
    methods: {
        sendAddGuest() {
            this.guests.push(this.guest)
            this.guest = ''
            this.updateGuests()
        },
        removeGuest(guest) {
            const index = this.guests.indexOf(guest)
            this.guests.splice(index, 1)
            this.updateGuests()
        },
        showRegiterSimple() {
            this.dialogRegisterSimpleGuest = true
        },
        resultRegisterGuest(guest) {
            this.guests.push(guest)
        },
        updateGuests() {
            this.$emit('updateGuests', this.guests)
        }


    }
}
</script>

<style scoped>
nodata {
    height: 170px;
}
</style>
