<template>
<v-card-text>
    <v-row>
        <v-col class="text-right">
            <v-btn :loading="loadingCheckin" x-large color="success" @click="sendCheckIn()">Check-In</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <AutocompleteSearchGuest v-model="guest" />
        </v-col>
    </v-row>
</v-card-text>
</template>

<script>
import AutocompleteSearchGuest from '@/components/AutocompleteSearchGuest.vue'
import { mapActions } from 'vuex'

export default {
    props: {
        room: {
            type: Object
        },
    },
    components: {
        AutocompleteSearchGuest,
    },
    data:()=>({
      loadingCheckin:false,
      guest:null,
    }),
    created() {
      //  console.log(this.room)
    },
    methods:{
      ...mapActions('reserved',['checkIn']),
      sendCheckIn(){
        this.loadingCheckin = true
        this.room.guest = this.guest
        this.checkIn(this.room).then((response)=>{
          this.$emit('close');  //envia evento para fechar o dialog
          this.$toast.success('Check-In realizado')
          this.$emit('updategrid');   //evento atualiza a grade de quartos
        }).finally(()=>{
          this.loadingCheckin = false
        })
      }
    }


}
</script>
