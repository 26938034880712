<template>
  <div>
    <v-card-actions>
      <v-row>
        <v-col class="text-right">
          <v-btn :loading="loadingCheckin" x-large color="success" @click="sendCheckIn()">Check-In</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <v-row no-gutters class="mt-4">
        <v-col>
          <AutocompleteSearchReserve v-model="reserve" @change="applyReserve"></AutocompleteSearchReserve>
        </v-col>
      </v-row>
      <v-row no-gutters class="">
        <v-col cols="3">
          <v-text-field v-model="dataCheckIn.car_license_plate" class="mr-2" outlined
            label="Placa Automóvel"></v-text-field>
        </v-col>
        <v-col offset="7" cols="2">
          <vuetify-money v-model="dataCheckIn.accommodation_value" label="Valor da Hospedagem" disabled
            outlined></vuetify-money>
        </v-col>
      </v-row>
      <v-row no-gutters class="">
        <v-col cols="3" class="mr-2">
          <SelectCategoryRoom :errors="errors.room_category_id" v-model="dataCheckIn.room_category_id" class="mr-4"
            @change="errors.room_category_id = ''" />
        </v-col>
        <v-col cols="2">
          <v-switch v-model="dataCheckIn.tariff_manual" true-value="yes" false-value="not"
            label="Tarifa Manual"></v-switch>
        </v-col>
        <v-col v-if="dataCheckIn.tariff_manual == 'not'" cols="">
          <SelectTariff :errors="errors.tariff_id" v-model="dataCheckIn.tariff_id" class="mr-4" @change="createTarif()"
            @object="setTariffObject" />
        </v-col>
        <v-col v-if="dataCheckIn.tariff_manual == 'yes'" cols="">
          <vuetify-money :erros="errors.tariff_manual_value" v-model="tariff_manual_value" outlined label="Tarifa"
            class="mr-4" />
        </v-col>
        <v-col cols="" class="ml-2">
          <input-date :errors="errors.check_out" v-model="dataCheckIn.check_out"
            @change="errors.check_out = ''; createTarif()" :dense="false" :label="'Check-out'"></input-date>
        </v-col>
        <v-col>
          <v-btn color="primary" class="ml-4" @click="showDialogTariffExtraordinary">
            <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            TARIFAS
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card outlined>
            <v-data-table dense :headers="headersgGuestUnknown" disable-sort :items="guest_unknown" :footer-props="{
              itemsPerPageText: 'Itens por pagina',
              itemsPerPageOptions: [5],
              showFirstLastPage: true,
            }">

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <TableGuest :errors="errors.guest" v-model="guest" />
        </v-col>
      </v-row>
    </v-card-text>


    <!-- DIALOG TARIFAS EXTRAORDINARIAS -->
    <v-dialog v-model="dialogTariffExtraordinary" persistent width="600">
      <v-card>
        <BarTitleDialog title="Tarifas" @close="dialogTariffExtraordinary = false" />
        <v-card-text>
          <!-- v-for=" tariff in editedItem.extraordinary_tariff   " -->
          <v-row no-gutters v-for="tariff in extraordinary_tariff" class="mb-2">

            <v-col>
              <vuetify-money :option="{ prefix: 'R$' }" v-model="tariff.value" dense outlined
                hide-details></vuetify-money>
            </v-col>
            <v-col>
              <v-text-field hide-details="" readonly class="ml-2" outlined dense :value="formatDate(tariff.date_start)"
                label="Data Inicio"></v-text-field>
              <!-- <input-date css="ml-2" v-model="tariff.date_start" :label="'Data Inicial'" :dense="true"></input-date> -->
            </v-col>
            <v-col>
              <v-text-field hide-details="" readonly class="ml-2" outlined dense :value="formatDate(tariff.date_end)"
                label="Data Fim"></v-text-field>
              <!-- <input-date css="ml-2" v-model="tariff.date_end" :label="'Data Final'" :dense="true"></input-date> -->
            </v-col>
            <!-- <v-col cols="1"> -->
            <!-- <v-btn icon color="error" class="ml-2" @click="removeTariffExtraordinary(tariff)" >
                            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn> -->
            <!-- </v-col> -->
          </v-row>
          <!-- <v-row>
                    <v-col class="text-center"> -->
          <!-- <v-btn rounded  color="secondary" @click="addTariffExtraordinary()" >
                            <v-icon>{{ icons.mdiPlus }}</v-icon>
                            Adicionar Tarifa
                        </v-btn> -->
          <!-- </v-col>
                </v-row> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn x-large color="secondary" text class="mr-4"
                @click="dialogTariffExtraordinary = false">fechar</v-btn>
              <v-btn x-large color="primary" @click="confirmExtraordinaryTariff()">OK</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AutocompleteSearchCompanyVue from '@/components/AutocompleteSearchCompany.vue'
import AutocompleteSearchReserve from '@/components/AutocompleteSearchReserve.vue'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import InputDate from '@/components/InputDate.vue'
import SelectCategoryRoom from '@/components/SelectCategoryRoom.vue'
import SelectTariff from '@/components/SelectTariff.vue'
import guest from '@/store/register/guest/guest'
import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { mapActions } from 'vuex'
import TableGuest from './TableGuest.vue'

export default {
  props: {
    room: {
      type: Object,
    },
  },
  components: {
    AutocompleteSearchCompanyVue,
    InputDate,
    TableGuest,
    SelectTariff,
    SelectCategoryRoom,
    AutocompleteSearchReserve,
    BarTitleDialog,
  },
  data: () => ({
    dialogTariffExtraordinary: false,
    extraordinary_tariff: [],
    tariffObject: {},
    headersgGuestUnknown: [
      {
        text: 'HÓSPEDES',
        align: 'start',
        value: 'name',
      },
    ],
    loadingCheckin: false,
    guest: [],
    config: {},
    guest_unknown: [],
    company: null,
    reserve: null,
    dataCheckIn: {
      tariff_manual: 'not',
    },
    tariff_manual_value: '',

    icons: {
      mdiDeleteOutline,
      mdiPlus,
      mdiPencilOutline,
    },
    errors: {
      room_category_id: null,
      tariff_id: null,
      check_in: null,
      check_out: null,
      company_id: null,
      guest: null,
      room: [],
    },
    tarifManualInitial: null //parta da gambiarra
  }),
  created() { 
    this.config = this.loadConfig().then(response => {
      this.config = response.data
    })
  },
  watch: {
    tariff_manual_value(val) {
      //gambiarra pq o onchange no comoponenete vutify-money não funciona
      if (val != this.tarifManualInitial) {
        this.createTarif()
      }

    },
    room() {
      this.reserve = null
      this.dataCheckIn = {
        tariff_manual: 'not',
      }
      this.guest = []
      this.guest_unknown = []
      this.extraordinary_tariff = []
    },
    guest() {
      if (this.guest.length > 0) {
        this.errors.guest = ''
      }
    },
  },
  computed: {
    guests() {
      //fitrar os hospedes q estao cadastrados
      let guests = this.guest.filter(guest => {
        return guest.origin == undefined
      })
      return guests
    },
  },
  methods: {
    ...mapActions('reserved', ['checkIn']),
    ...mapActions('config', ['loadConfig']),

    setTariffObject(object) {
      this.tariffObject = object
      this.calcAccommodationValue()
    },
    showDialogTariffExtraordinary() {
      this.dialogTariffExtraordinary = true
    },
    addTariffExtraordinary() {
      this.extraordinary_tariff.push({ value: 0, date_start: '', date_end: '' })
    },
    removeTariffExtraordinary(tariff) {
      const index = this.extraordinary_tariff.indexOf(tariff)
      this.extraordinary_tariff.splice(index, 1)
    },

    applyReserve(reserve) {
      this.dataCheckIn.with_iss = reserve.with_iss
      this.dataCheckIn.check_in = new Date(Date.now());
      if(this.dataCheckIn.check_in < reserve.check_out) {
        this.dataCheckIn.check_out = reserve.check_out
      }
      this.dataCheckIn.room_category_id = reserve.room_category_id
      this.dataCheckIn.tariff_manual = reserve.tariff_manual
      this.tariff_manual_value = reserve.tariff_manual_value
      this.tarifManualInitial = reserve.tariff_manual_value
      this.dataCheckIn.tariff_id = reserve.tariff_id
      this.tariffObject = reserve.tariff
      this.dataCheckIn.company_id = reserve.company_id
      this.guest = reserve.guest
      this.guest_unknown = reserve.guest_unknown
      this.extraordinary_tariff = reserve.extraordinary_tariff

      

      this.calcAccommodationValue()
    },
    createTarif() {
      const [hours, minutes] = this.config.checkout.split(':')
      this.errors.tariff_id = ''
      let local = new Date(Date.now());
      let checkIn = new Date(this.dateConvert(local));
      let checkOut = new Date(this.dataCheckIn.check_out)
      checkOut.setHours(checkOut.getHours() + 3);
      checkOut.setHours(hours)
      checkOut.setMinutes(minutes)
      this.dataCheckIn.check_in = checkIn.toISOString().split('T')[0]

      const diffInMs = checkOut - checkIn;
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      
      let value = 0

      console.log('diffInDays', diffInDays)

      if (this.dataCheckIn.tariff_manual == 'yes') {
        value = this.tariff_manual_value; //this.dataCheckIn.tariff_manual_value
      } else {
        value = this.tariffObject.value
      }
      let tariffs = []
      let date_start = ''
      let date_end = ''
      if (diffInDays < 1 && diffInDays>0) {
        date_start = checkIn.toISOString().split('T')[0]
        date_end = checkIn.toISOString().split('T')[0]
        tariffs.push({ value: value, date_start: date_start, date_end: date_end })
      }else if(diffInDays >= 1){
        for (let i = 1; i < diffInDays; i++) {
          date_start = checkIn.toISOString().split('T')[0]
          checkIn.setDate(checkIn.getDate() + 1)
          date_end = checkIn.toISOString().split('T')[0]
          tariffs.push({ value: value, date_start: date_start, date_end: date_end })
        }
      }

      this.extraordinary_tariff = tariffs
      this.calcAccommodationValue()
    },

    calcAccommodationValue() {
      let result = this.extraordinary_tariff.reduce((acc, tariff) => {
        return acc + parseFloat(tariff.value)
      }, 0);

      if (this.dataCheckIn.with_iss == 'yes') {
        //calc de iss
        if (result > 0) {
          let iss = result * 0.03
          result += iss
        }
      }

      this.dataCheckIn.accommodation_value = result
    },

    confirmExtraordinaryTariff() {
      this.calcAccommodationValue()
      this.dialogTariffExtraordinary = false
    },
    sendCheckIn() {

      //Trigger para atualizar a data de checkin e checkout e as tariffs
      this.createTarif();
      // valida os dados
      if (!this.validations()) return

      this.loadingCheckin = true
      this.dataCheckIn.guest = this.guests
      this.dataCheckIn.room_id = this.room.id
      if (this.reserve) {
        this.dataCheckIn.reserve_id = this.reserve.id
      }

      this.dataCheckIn.accommodation_item = this.extraordinary_tariff

      this.checkIn(this .dataCheckIn)
        .then(response => {
          this.$emit('close') //envia evento para fechar o dialog
          this.$toast.success('Check-In realizado')
          this.$emit('updategrid') //evento atualiza a grade de quartos
        })
        .finally(() => {
          this.loadingCheckin = false
        })
    },
    formatDate(date) {
      // const date = this.date
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Validacões
    validations() {
      let validate = true
      const [hours, minutes] = this.config.checkout.split(':')
      let checkIn = new Date(this.dataCheckIn.check_in);
      let checkOut = new Date(this.dataCheckIn.check_out)
      checkOut.setHours(checkOut.getHours() + 3);
      checkOut.setHours(hours);
      checkIn.setMinutes(minutes);



      //valida a cetegoria do quarto
      if (this.dataCheckIn.room_category_id == null || this.dataCheckIn.room_category_id == undefined) {
        this.errors.room_category_id = 'Campo obrigatório'
        validate = false
      }
      //valida tarifa padrao
      if (this.dataCheckIn.tariff_manual == 'not') {
        //se estiver abilitado para tarifa padrao
        if (this.dataCheckIn.tariff_id == null || this.dataCheckIn.tariff_id == undefined) {
          this.errors.tariff_id = 'Campo obrigatório'
          validate = false
        }
      }

      //valida tarifa manual
      if (this.dataCheckIn.tariff_manual == 'yes') {
        //se estiver abilitado para tarifa padrao
        if (
          this.tariff_manual_value == null ||
          this.tariff_manual_value == undefined ||
          this.tariff_manual_value == 0
        ) {
          this.errors.tariff_manual_value = 'Campo obrigatório'
          validate = false
        }
      }

      //valida data checkout
      if (
        this.dataCheckIn.check_out == null ||
        this.dataCheckIn.check_out == undefined ||
        this.dataCheckIn.check_out == ''
      ) {
        this.errors.check_out = 'Campo obrigatório'
        validate = false
      }

      //valida se a checkout e maior q adata de checkin
      if (checkIn > checkOut) {
        this.errors.check_out = 'Data de checkout deve ser maior que a data atual'
        validate = false
      }

      //valida se tem hopede ou empresa
      let guest = false

      if (this.guest.length == 0) {
        this.errors.guest = 'Obrigatório preencher hóspede'
        validate = false
      }

      return validate
    },

    dateConvert(date) {
      let dateStr = date.toLocaleString('pt-BR')
      let split = dateStr.split(',')
      split = split[0].split('/')
      return `${split[2]}-${split[1]}-${split[0]}`
    
    },

  },
}
</script>
