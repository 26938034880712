<template>
<v-autocomplete :error-messages="errors" v-model="guest" return-object :dense="dense" filled outlined label="Empresa" :items="guests" :loading="loading" :search-input.sync="searchKeyWord" item-text="fantasy_name" item-value="id" @click="search" @keyup="search" @change="emit">
    <!-- <template v-slot:item="{ item }">
        {{ item.id + ' - ' + item.fantasy_name }}
    </template> -->

    <template v-slot:no-data>
        <v-list-item>
            <v-list-item-title>
                <strong>{{ message }} </strong>
            </v-list-item-title>
        </v-list-item>
    </template>
</v-autocomplete>
</template>

<script>
import axios from '@/plugins/axios'
export default {
    props: {
        value: {},
        errors:{},
        product: {
            type: Object
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        guests: [],
        loading: false,
        guest: null,
        searchKeyWord: '',
        message: '',
    }),
    watch: {
        value(val) {
            this.guests = []
            this.guests.push(val)
            this.guest = val
        },
    },
    created() {
        if(this.value){
          this.guests = []
          this.guests.push(this.value)
          this.guest = this.value
        }


    },
    computed: {
      searchWord(){
        if(this.searchKeyWord==null){
          return ''
        }
          return this.searchKeyWord

      }
    },
    methods: {
        emit() {
            this.$emit('input', this.guest)
            this.$emit('change', this.guest)
        },
        search() {
            this.loading = true
            this.message = 'Carregando aguarde.'
            axios
                .post('helper/get_companies',{search:this.searchWord})
                .then(response => {
                    this.guests = response.data
                    if (this.guests.length == 0) {
                        this.message = 'Nenhum registro encontrado.'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

    },
}
</script>

<style>
</style>
