<template>
<v-select :error-messages="errors" outlined v-model="pdv" :loading="loading" :disabled="loading" label="Ponto de Venda" :items="getPdvs" item-text="description" item-value="id"></v-select>
</template>

<script>
import axios from '@/plugins/axios'
import {
    mapGetters,
    mapActions
} from 'vuex'
export default {

    components: {},
    props: {
        value: {},
        errors:{},
    },

    data: () => ({
        loading: false,
        pdv: '',
        pdvs: [],
    }),

    watch: {
        value(val) {
            this.category = val
        },
        pdv() {
            this.$emit('input', this.pdv)
            this.$emit('change')
        }

    },

    created() {
        this.loadPdvs
        this.pdvs = this.getPdvs
        this.pdv = this.value
        
    },
    computed: {
        ...mapGetters('select', ['getPdvs']),
        ...mapActions('select', ['loadPdvs']),
    },
    methods: {},
}
</script>
