<template>
<div>
    <v-row >
        <v-col v-for="( room, index ) in rooms" :key="index">
            <div @click="showRoom(room)" class="box_room" :class="room.status">
                <div class="text-center label_room_status">{{status(room.status)}}</div>
                <div class="text-center label_room">{{room.description}}</div>
                <div class="text-center label_category">{{ room.category.description }}</div>
                <!-- <div class="text-center label_room_time">{{room.status_time_remaining}}</div> -->
            </div>
        </v-col>
    </v-row>

    <v-dialog v-model="dialogRoom" fullscreen persistent no-click-animation>
        <v-card>
            <BarTitleDialog :title="descriptionRoom" @close="dialogRoom=false" />
            <dialog-busy :room="editedItem" v-model="dialogRoom" @updategrid="updateGrid()"  @close="dialogRoom=false"  v-if="inArray(editedItem.status,['periodo','ocupado'])" />
            <dialog-reserved @close="dialogRoom=false" @updategrid="updateGrid()" :room="editedItem"  v-if="inArray(editedItem.status,['reservado'])" />
            <dialog-status-room @close="dialogRoom=false" @updategrid="updateGrid()" :room="editedItem"  v-if="inArray(editedItem.status,['bloqueado','disponivel','diaria','conferencia','encerramento','limpeza','manutencao','pernoite','stand-by','sujo'],)" />
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import { mapActions } from 'vuex'
import DialogBusy from './_components/DialogBusy.vue'
import DialogReserved from './_components/DialogReserved.vue'
import DialogStatusRoom from './_components/DialogStatusRoom.vue'

export default {
  components: {
    DialogBusy,
    BarTitleDialog,
    DialogReserved,
    DialogStatusRoom,
  },
  data: () => ({
    dialogRoom: false,
    descriptionRoom: '',
    editedItemLoading: false,
    headers: [
      {
        text: 'Produto',
        align: 'start',
        value: 'name',
      },
      {
        text: 'QTDE',
        align: 'right',
        value: 'name',
      },
      {
        text: 'Un. (R$)',
        align: 'right',
        value: 'name',
      },
      {
        text: 'Total',
        align: 'right',
        value: 'name',
      },
      {
        text: '',
        align: 'end',
        value: 'action',
      },
    ],

    statusItems: [
      {
        value: 'sujo',
        text: 'Sujo',
      },
      {
        value: 'bloqueado',
        text: 'Bloqueado',
      },
      {
        value: 'diaria',
        text: 'Diaria',
      },
      {
        value: 'disponivel',
        text: 'Disponível',
      },
      {
        value: 'conferencia',
        text: 'Conferência',
      },
      {
        value: 'encerramento',
        text: 'Encerramento',
      },
      {
        value: 'limpeza',
        text: 'Limpeza',
      },
      {
        value: 'manutencao',
        text: 'Manutenção',
      },
      {
        value: 'ocupado',
        text: 'Ocupado',
      },
      {
        value: 'periodo',
        text: 'Período',
      },
      {
        value: 'pernoite',
        text: 'Pernoite',
      },
      {
        value: 'reservado',
        text: 'Reservado',
      },
      {
        value: 'stand-by',
        text: 'Stand-by',
      },
    ],
    rooms: [],
    editedItem: {},
    indexItem: null,
    icons: {},
  }),
  created() {
    this.inicialize()
    this.loadTariffs()
    this.loadRoomCategories()
  },
  computed: {},
  methods: {
    ...mapActions('manager_room', ['getAllRoom', 'update']),
    ...mapActions('select', ['loadTariffs', 'loadRoomCategories']),
    inicialize() {
      this.loading = true
      this.getAllRoom()
        .then(response => {
          this.rooms = response.data

          //se tiver parametro room_id na rota ja abri a tela do quarto
          // if(this.$route.params.room_id){
          //   const room =  this.rooms.find((room)=>{
          //     return room.id == this.$route.params.room_id
          //   })
          //   this.showRoom(room)
          // }
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateGrid() {
      this.inicialize()
    },
    showRoom(room) {
      this.indexItem = this.rooms.indexOf(room)
      this.editedItem = {
        ...room,
      }
      this.dialogRoom = true
      this.descriptionRoom = room.description.toUpperCase()
    },
    save(room) {
      this.editedItemLoading = true
      this.update(this.editedItem)
        .then(response => {
          Object.assign(this.rooms[this.indexItem], response.data)
          this.dialogRoom = false
        })
        .finally(() => {
          this.editedItemLoading = false
        })
    },
    status(status) {
      switch (status) {
        case 'sujo':
          return 'Sujo'
        case 'bloqueado':
          return 'Bloqueado'
        case 'diaria':
          return 'Diária'
        case 'disponivel':
          return 'Disponível'
        case 'conferencia':
          return 'Conferência'
        case 'encerramento':
          return 'Encerramento'
        case 'limpeza':
          return 'Limpeza'
        case 'manutencao':
          return 'Manutenção'
        case 'ocupado':
          return 'Ocupado'
        case 'periodo':
          return 'Período'
        case 'pernoite':
          return 'Pernoite'
        case 'reservado':
          return 'Reservado'
        case 'stand-by':
          return 'Stand-by'
      }
    },
    inArray(item, arr) {
      const result = arr.find(i => {
        return i == item
      })

      if (result) {
        return true
      }
      return false
    },
  },
  watch: {},
}
</script>

<style scoped>
.label_category {
  font-size: 13px;
  color: white;
  box-sizing: border-box;
}

.label_room {
  font-size: 20px;
  color: white;
  box-sizing: border-box;
  margin-top: 65px;
  padding-top: 5px;
  font-weight: bold;
}

.label_room_status {
  font-size: 15px;
  color: white;
  box-sizing: border-box;
  padding-top: 4px;
}

.label_room_time {
  font-size: 12px;
  color: white;
}

.box_room {
  background-size: 100%;
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.bloqueado {
  background-image: url('../../assets/images/rooms/bloqueado.png');
}

.diaria {
  background-image: url('../../assets/images/rooms/diaria.png');
}

.disponivel {
  background-image: url('../../assets/images/rooms/disponivel.png');
}

.conferencia {
  background-image: url('../../assets/images/rooms/conferencia.png');
}

.encerramento {
  background-image: url('../../assets/images/rooms/encerramento.png');
}

.limpeza {
  background-image: url('../../assets/images/rooms/limpeza.png');
}

.manutencao {
  background-image: url('../../assets/images/rooms/manutencao.png');
}

.ocupado {
  background-image: url('../../assets/images/rooms/ocupado.png');
}

.periodo {
  background-image: url('../../assets/images/rooms/periodo.png');
}

.pernoite {
  background-image: url('../../assets/images/rooms/pernoite.png');
}

.reservado {
  background-image: url('../../assets/images/rooms/reservado.png');
}

.stand-by {
  background-image: url('../../assets/images/rooms/stand-by.png');
}

.sujo {
  background-image: url('../../assets/images/rooms/sujo.png');
}
</style>
