<template>
<v-select :error-messages="errors" outlined v-model="category" :loading="loading" :disabled="loading" label="Categoria de Quarto" :items="roomCategorys" item-text="description" item-value="id" ></v-select>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
export default {

    components: {},
    props: {
        value: {},
        errors:{}
    },

    data: () => ({
        loading: false,
        category: '',
        roomCategorys:[],
    }),

    watch: {
        value(val) {
            this.category = val
        },
        category(){
          this.$emit('input',this.category)
          this.$emit('change')
        }

    },

    created() {
        this.roomCategorys = this.getRoomCategories
        this.category = this.value
        // this.loading = true
        // axios
        //     .get('helper/get_room_categorys')
        //     .then(response => {
        //       this.roomCategorys = response.data
        //     })
        //     .finally(() => {
        //         this.loading = false
        //     })
    },
    computed: {
       ...mapGetters('select',['getRoomCategories'])
    },

    methods: {},
}
</script>
