<template>
<v-autocomplete  hide-no-data  v-model="product"  :items="items" return-object :dense="dense" filled outlined label="Produtos" :loading="loading" :search-input.sync="searchKeyWord" item-text="text"  item-value="id" @click="search" @keyup="search" @change="change">

    <!-- <template v-slot:item="{ item }">
      {{ item.group.code+'-'+item.code+' - '+item.description }}
    </template> -->

    <!-- <template v-slot:no-data>
        <v-list-item>
            <v-list-item-title>
                <strong>{{ message }}</strong>
            </v-list-item-title>
        </v-list-item>
    </template> -->
</v-autocomplete>
</template>

<script>
import axios from '@/plugins/axios'
export default {
    props: {
        value: {},
        dense: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        products: [],
        loading: false,
        product:null,
        searchKeyWord: '',
        message: '',
    }),
    watch: {
        value(val) {
            // this.products = []
            // this.products.push(val)
            // this.product = val
        },
        product(){
           this.$emit('input',this.product)
        }
    },
    created() {
        if(this.value){
          // this.products = []
          // this.products.push(this.value)
          // this.product = this.value
        }


    },
    computed: {
      items(){
         let products = this.products.map((product)=>{
           return  Object.assign({}, product)
         })
        //  console.log(products);
         return products;
      },
      searchWord() {
            if (this.searchKeyWord == null) {
                return ''
            }
            return this.searchKeyWord
        }
    },

    methods: {
        change(){
            this.$emit('input', this.guest)
            this.$emit('change', this.guest)
        },
        search() {

            //if (this.searchWord.length < 2) return

            this.loading = true
            this.message = 'Carregando aguarde.'

            axios
                .post('helper/get_products',{search:this.searchWord})
                .then(response => {
                    this.products = response.data
                    if (this.products.length == 0) {
                        this.message = 'Nenhum registro encontrado.'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

    },
}
</script>

<style>
</style>
