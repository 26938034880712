<template>
<div>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <BarTitleDialog title="cadastro de hóspede" @close="dialog = false"></BarTitleDialog>
      <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="registerGuest.name" outlined label="Nome"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="registerGuest.cpf" v-mask="'###.###.###-##'" outlined label="CPF"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>

          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field v-model="registerGuest.email"  outlined label="Email"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field v-model="registerGuest.cell_phone"  v-mask="'(##)#####-####'"  outlined label="Celular"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="registerGuest.home_phone"  v-mask="'(##)####-####'" class="ml-2"  outlined label="Telefone Fixo"></v-text-field>
            </v-col>
          </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="text-right">
            <v-btn class="mr-2" color="secondary" @click="dialog = false" x-large text>Fechar</v-btn>
            <v-btn color="primary" :loading="loading" :disabled="loading" x-large @click="save()">Salvar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
    props:{
      value:{type:Boolean}
    },
    components: {
      BarTitleDialog
    },
    data: () => ({
      dialog:false,
      registerGuest:{},

      loading:false,
    }),
    created(){
      this.registerGuest = {}
    },
    watch:{
      value(val){
        this.dialog= val
        if(val){
          this.registerGuest = {}
        }
      },
      dialog(val){
        this.$emit('input',val)
      }
    },
    computed: {

    },
    methods: {
      ...mapActions('guest',['store']),
      // ...mapMutations('reserve', ['addGuest']),
      save(){
        this.loading = true
        this.store(this.registerGuest).then((response)=>{
          this.$emit('result',response.data)
          this.$toast.success('Hóspede salvo com sucesso')
          this.dialog = false
        }).finally(()=>{
          this.loading = false
        })


      }

    }
}
</script>

<style scoped>


</style>
